import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import Callout from '../../components/Callout';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import ToastBasic from '../../examples/Toast/Basic';
import ToastDismissible from '../../examples/Toast/Dismissible';
import ToastStacking from '../../examples/Toast/Stacking';
import ToastPlacement from '../../examples/Toast/Placement';
import ToastPlacementMulti from '../../examples/Toast/PlacementMulti';
import ToastAutohide from '../../examples/Toast/Autohide';
export const query = graphql`
  query ToastQuery {
    Toast: componentMetadata(displayName: { eq: "Toast" }) {
      ...ComponentApi_metadata
    }
    ToastHeader: componentMetadata(displayName: { eq: "ToastHeader" }) {
      ...ComponentApi_metadata
    }
    ToastBody: componentMetadata(displayName: { eq: "ToastBody" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "toasts"
    }}>{`Toasts`}</h1>
    <p className="lead">
  Push notifications to your visitors with a toast, a lightweight and easily customizable alert message.
    </p>
    <p>{`Toasts are lightweight notifications designed to mimic the push notifications that have been popularized by mobile and desktop operating systems. They’re built with flexbox, so they’re easy to align and position.`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "basic"
    }}>{`Basic`}</h3>
    <p>{`To encourage extensible and predictable toasts, we recommend a header and body. Toast headers use display: flex, allowing easy alignment of content thanks to our margin and flexbox utilities.`}</p>
    <p>{`Toasts are as flexible as you need and have very little required markup. At a minimum, we require a single element to contain your “toasted” content and strongly encourage a dismiss button.`}</p>
    <ReactPlayground codeText={ToastBasic} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "dismissible"
    }}>{`Dismissible`}</h3>
    <ReactPlayground codeText={ToastDismissible} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "stacking"
    }}>{`Stacking`}</h3>
    <p>{`When you have multiple toasts, we default to vertically stacking them in a readable manner.`}</p>
    <ReactPlayground codeText={ToastStacking} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "placement"
    }}>{`Placement`}</h3>
    <p>{`Place toasts with custom CSS as you need them. The top right is often used for notifications, as is the top middle.`}</p>
    <ReactPlayground codeText={ToastPlacement} mdxType="ReactPlayground" />
    <p>{`For systems that generate more notifications, consider using a wrapping element so they can easily stack.`}</p>
    <ReactPlayground codeText={ToastPlacementMulti} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "autohide"
    }}>{`Autohide`}</h3>
    <p>{`A Toast can also automatically hide after X milliseconds. For that, use the `}<inlineCode parentName="p">{`autohide`}</inlineCode>{` prop in combination with `}<inlineCode parentName="p">{`delay`}</inlineCode>{` the prop to sepecify the delay. But be aware, that it will only trigger the `}<inlineCode parentName="p">{`onClose`}</inlineCode>{` function, you have to set manually the show property.`}</p>
    <ReactPlayground codeText={ToastAutohide} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Toast} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ToastHeader} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ToastBody} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      